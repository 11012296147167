<template>
  <v-row dense>
    <v-col cols="12" md="6">
      <search-form-field
        :field="fieldFrom"
        :removable="!visibleFieldTo && removable"
        :visible-include="!visibleFieldTo && visibleInclude"
        :disabled-include="disabledInclude"
        @trash="onTrash"
        @operatorChanged="onOperatorChanged"
      />
    </v-col>
    <v-col cols="12" md="6" v-if="visibleFieldTo">
      <search-form-field
        :field="fieldTo"
        :removable="removable"
        :visibleInclude="visibleInclude"
        :disabled-include="disabledInclude"
        @trash="onTrash"
      />
    </v-col>
  </v-row>
</template>
<script>
// design
import { iconTrash } from "@/design/icon/iconConst";
import { deleteSettingsMixin } from "@/mixins/shared/base/settings/deleteSettingsMixin";
import { isRangeFromOperator } from "@/model/field/fieldModel";

export default {
  name: "SearchFormFieldRange",
  mixins: [deleteSettingsMixin],
  components: {
    SearchFormField: () => import("@/components/field/SearchFormField")
  },
  data() {
    return {
      visibleFieldTo: false,
      iconTrash: iconTrash
    };
  },
  props: {
    fieldFrom: {},
    fieldTo: {},
    /**
     * Is removable field
     */
    removable: {
      type: Boolean,
      default: false
    },
    visibleInclude: {
      type: Boolean,
      default: true
    },
    disabledInclude: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onOperatorChanged() {
      this.visibleFieldTo = isRangeFromOperator(this.fieldFrom.operator);
    },
    onTrash() {
      this.$emit("trash");
    }
  }
};
</script>
